import React, {useState} from 'react';
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';

import * as Yup from 'yup';
import {FORMAT_MESSAGE, KUPI_API, REQUIRED_MESSAGE} from './constants';
import {postData} from './helpers';

const OrderSchema = Yup.object().shape({
    name: Yup.string().min(2).required(REQUIRED_MESSAGE),
    email: Yup.string().email(FORMAT_MESSAGE).required(REQUIRED_MESSAGE),
    telephone: Yup.string().required(REQUIRED_MESSAGE),
    products: Yup.array()
        .of(
            Yup.object().shape({
                url: Yup.string().url().required(REQUIRED_MESSAGE),
                price: Yup.number(FORMAT_MESSAGE).transform((_value, originalValue) => Number(originalValue.replace(/,/, '.'))).typeError(FORMAT_MESSAGE)
                    .min(0.01, FORMAT_MESSAGE).positive(FORMAT_MESSAGE).required(REQUIRED_MESSAGE),
                qty: Yup.number(FORMAT_MESSAGE).typeError(FORMAT_MESSAGE).min(1, FORMAT_MESSAGE).integer(FORMAT_MESSAGE).required(REQUIRED_MESSAGE),
            })
        ),
});

const calculateTotalOrderAmount = (products) => {
    let totalValue = products.reduce((total, product) => (parseInt(product.qty, 10) * (product.price ? Number(product.price.replace(/,/, '.')) : 0 || 0)) + total, 0) || 0;

    return totalValue ? totalValue.toFixed(2) : '...';
};

const default_values = {'products': [{'url': ''}]};

const OrderFormApp = () => {
    const {register, control, handleSubmit, errors, reset} = useForm({
        mode: "onChange",
        resolver: yupResolver(OrderSchema),
        defaultValues: default_values
    });

    const {fields, append, remove } = useFieldArray({
        control,
        name: "products",
    });
    const products = useWatch({ control, name: "products" });
    const totalOrderAmount = calculateTotalOrderAmount(products);

    const [isSubmitting, setIsSubmitting] = useState(false);

    const onSubmit = (data) => {
        setIsSubmitting(true);
        postData(`${KUPI_API}order/`, data).then(result => {
            reset(default_values);
            alert('Ваш заказ успешно размещен. Мы свяжемся с вами в ближайшее время.')
        }).catch(error => {
            alert('Не удалось разместить заказ. Пожалуйста, свяжитесь с нами по контактам на сайте.')
        }).finally(() => {
            setIsSubmitting(false);
        });
    };

    return (
        <form className="container" onSubmit={handleSubmit(onSubmit)}>
            <div className="calc-bg">
                <div className="grid">

                    <div className="l">
                        <h2 className="h2">Оформить заказ:</h2>
                        <div className="row">
                            <div className="col">
                                <input
                                    type="text"
                                    placeholder="Ваше имя"
                                    name="name"
                                    defaultValue=""
                                    ref={register({required: true})}
                                />
                                {errors.name && <span style={{color: 'red'}}>Проверьте поле</span>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input
                                    type="text"
                                    placeholder="Email для связи"
                                    name="email"
                                    defaultValue=""
                                    ref={register({required: true})}
                                />
                                {errors.email && <span style={{color: 'red'}}>Проверьте поле</span>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input
                                    type="text"
                                    placeholder="Телефон для связи через Telegram"
                                    name="telephone"
                                    defaultValue=""
                                    ref={register({required: true})}
                                />
                                {errors.telephone && <span style={{color: 'red'}}>Проверьте поле</span>}
                            </div>
                        </div>

                        {fields.map((item, index) => (
                            <div key={item.id}>
                                <div className="row">
                                    <div className="col" style={{marginLeft: '-20px', width: '10px', display: 'flex', alignItems: 'center'}}>{index + 1}.</div>
                                    <div className="col">
                                        <input
                                            type="text"
                                            placeholder="Ссылка на товар"
                                            name={`products[${index}].url`}
                                            defaultValue={item.url}
                                            ref={register({required: true})}
                                        />
                                        {errors?.['products']?.[index]?.['url'] && <span style={{color: 'red'}}>Проверьте поле</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{width: '24%'}}>
                                        <input
                                            type="text"
                                            placeholder="Цена"
                                            name={`products[${index}].price`}
                                            defaultValue={item.price}
                                            ref={register({required: true})}
                                        />
                                        {errors?.['products']?.[index]?.['price'] && <span style={{color: 'red'}}>Проверьте поле</span>}
                                    </div>
                                    <div className="col" style={{width: '24%'}}>
                                        <input
                                            type="text"
                                            placeholder="Кол-во"
                                            name={`products[${index}].qty`}
                                            defaultValue={item.qty}
                                            ref={register({required: true})}
                                        />
                                        {errors?.['products']?.[index]?.['qty'] && <span style={{color: 'red'}}>Проверьте поле</span>}
                                    </div>
                                    <div className="col" style={{width: '24%'}}>
                                        <input
                                            type="text"
                                            placeholder="Цвет"
                                            name={`products[${index}].color`}
                                            defaultValue={item.color}
                                            ref={register({required: false})}
                                        />
                                    </div>
                                    <div className="col" style={{width: '24%'}}>
                                        <input
                                            type="text"
                                            placeholder="Размер"
                                            name={`products[${index}].size`}
                                            defaultValue={item.size}
                                            ref={register({required: false})}
                                        />
                                    </div>
                                </div>
                                {index !== 0 &&
                                <div className="row">
                                    <div className="col" style={{
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center'
                                    }}>
                                        <button
                                            type="button"
                                            className={'btn btn-danger btn-small'}
                                            onClick={() => remove(index)}
                                        >
                                            X
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        ))}


                        <div className="row">
                            <div className="col" style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center'
                            }}>
                                <button
                                    type="button"
                                    className={'btn btn-blue btn-small'}
                                    onClick={() => append({ url: "", qty: 1})}
                                >
                                    + еще товар
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <input type="text" placeholder="Дополнительные пожелания..." name="comment"
                                       defaultValue="" ref={register({required: false})}/>
                            </div>
                        </div>
                        <div className="row">
                            <div className="hold-delivery">
                                <div className="corner tl"></div>
                                <div className="corner tr"></div>
                                <div className="corner bl"></div>
                                <div className="corner br"></div>
                            </div>

                        </div>
                        <div className="submit">
                            <button className="btn btn-blue" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Подождите...' : 'Оформить'}</button>
                        </div>
                    </div>
                    <div className="r" style={{alignItems: 'flex-end', flexDirection: 'row'}}>
                        <div className="result" style={{minHeight: '500px'}}>
                            <div className="t">
                                <div className="text">
                                    <strong>Стоимость услуги:<br/></strong>
                                    10% от стоимости товаров.<br/>
                                    доставка оплачивается отдельно.
                                </div>
                                <div className="big">${totalOrderAmount ? totalOrderAmount : '...'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    );
};

export default OrderFormApp;
