import React from 'react';
import ReactDOM from 'react-dom';
import CalculatorApp from './CalculatorApp';
import OrderFormApp from './OrderFormApp';
import PaymentFormApp from './PaymentFormApp';

ReactDOM.render(
  <React.StrictMode>
    <CalculatorApp />
  </React.StrictMode>,
  document.getElementById('calculator_container')
);

ReactDOM.render(
  <React.StrictMode>
    <OrderFormApp />
  </React.StrictMode>,
  document.getElementById('order-form')
);


ReactDOM.render(
  <React.StrictMode>
    <PaymentFormApp />
  </React.StrictMode>,
  document.getElementById('payment-form')
);
