import React, {useCallback} from 'react';

const API_URL = 'https://api.wikishop.us/api/v1'

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            const error = data || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

const convertToNumber = (input) => {
    let value = String(input);
    // remove all characters that aren't digit or dot
    value = value.replace(/[^0-9.]/g, '');
    // replace multiple dots with a single dot
    value = value.replace(/\.+/g, '.');
    // only allow 2 digits after a dot
    value = value.replace(/(.*\.[0-9][0-9]?).*/g, '$1');
    // replace multiple zeros with a single one
    value = value.replace(/^0+(.*)$/, '0$1');
    // remove leading zero and dot
    value = value.replace(/^0([^.].*)$/, '$1');
    value = value.replace(/^\.([^.].*)$/, '$1');
    return value;
};

const CalculatorApp = () => {
    const [weight, setWeight] = React.useState('1.00');
    const [weightType, setWeightType] = React.useState('kg');

    const [calcResult, setCalcResult] = React.useState(null);

    const fetchCalc = useCallback(() => {
        const final_weight = weightType === 'kg' ? weight : (weight * Number('0.453592')).toFixed(2);
        const requestOptions = {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({
                weight: final_weight,
                delivery_method: 1
            })
        };

        fetch(`${API_URL}/client/calculator/`, requestOptions).then(handleResponse).then(
            response => {
                setCalcResult(response);
            },
            error => {}
        );
    }, [weight, weightType]);

    React.useEffect(() => {
        if (weight) {
            fetchCalc();
        }
    }, [weight, weightType, fetchCalc]);

    return (
        <div className="container">
            <div className="calc-bg">
                <div className="grid">
                    <div className="l">
                        <h2 className="h2">Сколько стоит доставка?</h2>
                        <div className="row">
                            <div className="col wid-weight">
                                <input type="text" placeholder="Вес" pattern="\d*" value={weight}
                                       onChange={event => setWeight(convertToNumber(event.target.value))}/>
                            </div>
                            <div className="col wid-type">
                                <select value={weightType} onChange={event => setWeightType(event.target.value)}>
                                    <option value="kg">kg</option>
                                    <option value="lbs">lbs</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <select>
                                    <option>город доставки...</option>
                                    <option>Ташкент</option>
                                    <option>Другие города Узбекистана</option>
                                </select>
                            </div>
                        </div>
                        <div className="row">
                            <div className="hold-delivery">
                                <div className="corner tl"></div>
                                <div className="corner tr"></div>
                                <div className="corner bl"></div>
                                <div className="corner br"></div>
                            </div>
                        </div>
                        <div className="submit">
                            <button className="btn btn-blue" onClick={fetchCalc}>Рассчитать</button>
                        </div>
                    </div>
                    <div className="r">
                        <div className="result">
                            <div className="t">
                                <div className="text">
                                    <strong>Методы доставки:<br/></strong>
                                    до двери - $3.00<br/>
                                    самовывоз с&nbsp;ближайшего пункта самовывоза - бесплатно.<br/>
                                </div>

                                <div className="big">${calcResult ? calcResult.delivery_price : '...'}</div>
                            </div>
                            <div className="b">
                                <div><strong>Через нас выгоднее!</strong><br/>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CalculatorApp;
