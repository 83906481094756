import React, {useState, useEffect} from 'react';
import {useFieldArray, useForm, useWatch} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {FORMAT_MESSAGE, KUPI_API, REQUIRED_MESSAGE} from './constants';


const PaymentSchema = Yup.object().shape({
    amount: Yup.number(FORMAT_MESSAGE).transform((_value, originalValue) => Number(originalValue.replace(/,/, '.'))).typeError(FORMAT_MESSAGE)
                    .min(1, FORMAT_MESSAGE).positive(FORMAT_MESSAGE).required(REQUIRED_MESSAGE),
    comment: Yup.string().required(REQUIRED_MESSAGE),

});

const fetchCheckoutSession = async ( amount, comment ) => {
  return fetch(`${KUPI_API}create-checkout-session`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
        amount: amount,
        comment: comment
    }),
  }).then((res) => res.json());
};

const PaymentFormApp = () => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const {register, control, handleSubmit, errors, reset} = useForm({
        mode: "onChange",
        resolver: yupResolver(PaymentSchema),
    });

    const onSubmit = (data) => {
        setIsSubmitting(true);

        fetchCheckoutSession(data.amount, data.comment).then(result => {
            window.location.href = "/redirect_to_stripe.html?sessionId=" + result.session;
        }).catch(() => {
            window.alert('Не удалось оформить оплату. Пожалуйста, свяжитесь с нами')
        }).finally(() => {
            setIsSubmitting(false);
        })
    };

    return (
        <form className="container" onSubmit={handleSubmit(onSubmit)}>
            <div className="calc-bg">
                <div className="grid">

                    <div className="l">
                        <h2 className="h2">Оплатить заказ:</h2>
                        <div className="row">
                            <div className="col">
                                <input
                                    type="number"
                                    placeholder="Сумма оплаты"
                                    name="amount"
                                    defaultValue=""
                                    ref={register({required: true})}
                                />
                                {errors.amount && <span style={{color: 'red'}}>Проверьте поле</span>}

                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <input type="text" placeholder="Комментарий" name="comment" defaultValue="" ref={register({required: true})}/>
                                {errors.comment && <span style={{color: 'red'}}>Проверьте поле</span>}
                            </div>
                        </div>
                        <div className="row">
                            <div className="hold-delivery">
                                <div className="corner tl"></div>
                                <div className="corner tr"></div>
                                <div className="corner bl"></div>
                                <div className="corner br"></div>
                            </div>

                        </div>
                        <div className="submit">
                            <button className="btn btn-blue" type="submit" disabled={isSubmitting}>{isSubmitting ? 'Подождите...' : 'Оплатить'}</button>
                        </div>
                    </div>
                </div>
            </div>

        </form>
    );
};

export default PaymentFormApp;
